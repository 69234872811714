import React from "react"
import Layout from "../components/layout";
import PageTitle from "../components/page_title";
import AboutAnimation from "../components/about_animation";
import Seo from "../components/seo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const educationList = [
  { year: '2024', title: "Master in Software Development for Web and Mobile", institution: "Universidad Internacional de Valencia", link: "https://www.universidadviu.com/es/" },
  { year: '2019', title: "Master in IT Management and Data Analytics", institution: "Universidad Carlos III de Madrid", link: "https://www.uc3m.es/" },
  { year: '2014', title: "Master in Project Management", institution: "Universidad Interamericana de Panamá", link: "https://www.uip.edu.pa/" },
  { year: '2017', title: "Postgraduate Studies in Higher Education Pedagogy", institution: "Universidad Interamericana de Panamá", link: "https://www.uip.edu.pa/" },
  { year: '2013', title: "Postgraduate Studies in Project Design", institution: "Universidad Interamericana de Panamá", link: "https://www.uip.edu.pa/" },
  { year: '2011', title: "B.Sc. in Electronics and Communications Engineering", institution: "Universidad Interamericana de Panamá", link: "https://www.uip.edu.pa/" },
];

const certificationsList = [
  { title: "Professional Scrum Product Owner Certified I (PSPO I)", institution: "Scrum.org", year: "2024", link: "https://www.scrum.org/user/521321" },
  { title: "Professional Scrum Master Certified I (PSM II)", institution: "Scrum.org", year: "2024", link: "https://www.scrum.org/user/521321" },
  { title: "Professional Scrum Master Certified I (PSM I)", institution: "Scrum.org", year: "2019", link: "https://www.scrum.org/user/521321" },
  { title: "Scrum Master Certified", institution: "ScrumStudy", year: "2016", link: "https://www.scrumstudy.com/" },
  { title: "COBIT 5 Foundation", institution: "APMG", year: "2015", link: "https://www.apmg-international.com/" },
  { title: "ITIL® Foundation", institution: "AXELOS", year: "2015", link: "https://www.axelos.com/" },
  { title: "IPMA® Level D", institution: "IPMA", year: "2014", link: "https://www.ipma.world/" },
];

const skillsList = [
  ["💻", "Back-End Web Development (Rails, Laravel)"],
  ["🌐", "Front-End Web Development (React, Next.js, Vue, Angular)"],
  ["📱", "Mobile Development (React Native, Expo)"],
  ["🗄️", "Database design and optimization (SQL and NoSQL)"],
  ["🧪", "Testing and Automation (Jest, Capybara, RSpec, PHPUnit)"],
  ["🏗️", "Software Architecture, Best Practices, and Design Patterns"],
  ["🎨", "User Experience, User Interface Design, and Accessibility"],
  ["🔄", "CI/CD pipelines and automation"],
  ["🔄", "Agile/Lean/Product oriented mindset"],
];

const professionalPhilosophyList = [
  ["🎯", "Creating accessible, usable, and beautiful products"],
  ["👥", "User-centric software design and development"],
  ["🤝", "Collaborative problem-solving and knowledge sharing"],
  ["✨", "Writing clean, well-documented, and testable code"],
  ["⚖️", "Balancing technical excellence with business objectives"],
  ["🔍", "Understanding the 'why' behind the 'what'"],
];

const beyondCodeList = [
  ["🏞️", "Exploring nature, traveling, and photography"],
  ["🧑‍🏫", "Mentoring aspiring developers. If you are one, let's talk"],
  ["📚", "Reading, having a cup of great Panamanian coffee, spending time at the beach"],
];

const About = () => {
  return (
    <Layout>
      <PageTitle>About me</PageTitle>

      <section className="mb-16 lg:flex lg:items-start lg:gap-8">
        <div className="lg:w-2/3">
          <p className="mb-4">
            As a seasoned Software Engineer with over a decade of experience, I've dedicated my career to crafting <em className="text-accent-subtle">elegant solutions for real-world problems</em>. My passion lies in the intersection of technology and practical, <em className="text-accent-subtle">user-centric design implementation</em>.
          </p>
          <p className="mb-4">
            Throughout my journey, I've had the privilege of working on diverse projects that have shaped my expertise in Full-Stack Web Development, and Mobile Development. I believe in the power of technology to transform businesses and improve customer experiences.
          </p>
          <p className="mb-0">
            I'm a strong believer in the <em className="text-accent-subtle">Agile mindset</em> and the importance of collaboration in delivering high-quality software. I thrive in environments that encourage <em className="text-accent-subtle">Creativity, Innovation, and Continuous Improvement</em>.
          </p>
        </div>
        <div className="hidden lg:block lg:w-1/3">
          <div className="aspect-square pt-8">
            <AboutAnimation />
          </div>
        </div>
      </section>

      <section className="mb-16">
        <h2 className="text-3xl font-bold mb-6 text-primary">Education & Certifications</h2>
        <div className="grid lg:grid-cols-2 gap-8">
          <div className="bg-surface rounded-lg p-6 shadow-md">
            <h3 className="text-2xl font-semibold mb-4 text-secondary">Education</h3>
            <ul className="space-y-3 mb-0">
              {educationList.map((item, index) => (
                <ListItemWithInfo key={index} item={item} />
              ))}
            </ul>
          </div>
          <div className="bg-surface rounded-lg p-6 shadow-md">
            <h3 className="text-2xl font-semibold mb-4 text-secondary">Certifications</h3>
            <ul className="space-y-3 mb-0">
              {certificationsList.map((item, index) => (
                <ListItemWithInfo key={index} item={item} />
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className="mb-16">
        <h2 className="text-3xl font-bold mb-6 text-primary">Skills & Philosophy</h2>
        <div className="grid lg:grid-cols-2 gap-8">
          <div className="bg-surface rounded-lg p-6 shadow-md">
            <h3 className="text-2xl font-semibold mb-4 text-secondary">Technical Expertise</h3>
            <ul className="space-y-3 mb-0">
              {skillsList.map(([emoji, skill], index) => (
                <li key={index} className="flex items-start">
                  <span className="text-primary mr-2">{emoji}</span>
                  <span>{skill}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="bg-surface rounded-lg p-6 shadow-md">
            <h3 className="text-2xl font-semibold mb-4 text-secondary">Professional Philosophy</h3>
            <p className="mb-4">
              I approach each project with a blend of creativity and analytical thinking. My goal is not just to write code, but to create better products.
            </p>
            <p className="mb-4">
              <strong className="text-accent-subtle">I code for people, not for machines.</strong>
            </p>
            <ul className="space-y-3 mb-0">
              {professionalPhilosophyList.map(([emoji, philosophy], index) => (
                <li key={index} className="flex items-start">
                  <span className="text-primary mr-2">{emoji}</span>
                  <span>{`${philosophy}.`}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className="mb-16">
        <h2 className="text-3xl font-bold mb-6 text-primary">Beyond Code</h2>
        <div className="bg-surface rounded-lg p-6 shadow-md">
          <p className="mb-4">
            When I'm not immersed in lines of code or planning the next project, you can find me:
          </p>
          <ul className="space-y-3">
            {beyondCodeList.map(([emoji, activity], index) => (
              <li key={index} className="flex items-start">
                <span className="text-primary mr-2">{emoji}</span>
                <span>{`${activity}.`}</span>
              </li>
            ))}
          </ul>
          <p className="mt-4 mb-0 text-accent-subtle font-semibold">
            {"I believe that a well-rounded life fuels creativity and brings fresh perspectives to problem-solving."}
          </p>
        </div>
      </section>
    </Layout>
  );
};

function ListItemWithInfo({ item }: { item: { year: string, title: string, institution: string, link: string } }) {
  return (
    <li className="flex items-start">
      <span className="flex flex-row text-sm gap-2">
        <small className="text-text-secondary">{`${item.year}`}</small>

        <div className="flex flex-col items-start gap-2">
          <span>{item.title}</span>
          {item.link && <a href={item.link} target="_blank" rel="noopener noreferrer" title={item.institution} className="text-link text-xs underline hover:text-link-hover"><FontAwesomeIcon icon={faLink} className="mr-1" />{item.institution}</a>}
        </div>
      </span>
    </li>
  );
}

export const Head = () => <Seo title="About me" />;

export default About;
