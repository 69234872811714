import React from 'react';
import aboutAnimationData from './about_animation_data.json';
import Lottie from 'lottie-react';

const AboutAnimation = () => {
  return (
    <Lottie animationData={aboutAnimationData} loop={true} />
  );
};

export default AboutAnimation;
